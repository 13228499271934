@use '../../../include' as *;

.columnsSection {
	background-color: var(--bw-background);

	&.columns {
		display: flex;
		flex-direction: column;
		border-top: solid 1px var(--bw-foreground);

		@media #{$lg} {
			flex-direction: row;

			// TODO(amatlash): define why span is there and kill it.
			span {
				flex: 1 1;
			}
		}
	}

	&.grid {
		display: grid;
		grid-template-columns: 1fr;
		gap: rem(32);
		padding: 0 rem(20);

		@media #{$lg} {
			max-width: 1440px;
			gap: rem(68) rem(56);
			grid-template-columns: 1fr 1fr 1fr;
			padding: rem(40) rem(120) rem(108) rem(120);
		}
	}
}
