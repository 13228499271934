@use '../../../include' as *;

.columnsItem {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: rem(24);
	flex: 1;
	padding: rem(54) rem(20) rem(48);
	border-bottom: solid 1px var(--bw-foreground);

	@media #{$lg} {
		padding: rem(54);

		&:first-child {
			padding-left: rem(120);
		}

		&:last-child {
			padding-right: rem(120);
		}

		&:not(:last-child) {
			border-right: solid 1px var(--bw-foreground);
		}
	}

	&:hover {
		.text {
			u {
				background-size: 100% 100%;
			}
		}
	}

	.title {
		@media #{$lg} {
			min-height: rem(56);
		}

		p {
			font-size: rem(28);
			font-weight: 300;
			line-height: 100%;

			b {
				font-size: rem(28);
				font-weight: 300;
				line-height: 100%;
				color: var(--bw-text-accent);
			}
		}
	}

	.text {
		u {
			text-decoration: none;
			background-image: linear-gradient(
				var(--bw-text-accent),
				var(--bw-text-accent)
			);
			background-position: 0 calc(100% - rem(1));
			background-repeat: no-repeat;
			background-size: 0 100%;
			transition: 0.35s ease;
		}
	}
}
